@import "../../variables.scss";

.ps5-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(black, 0.3);
  backdrop-filter: blur(2px);
  display: none;
  justify-content: center;
  align-items: center; 

  &.exit-active,
  &.enter {
    opacity: 0;
    transition: ($animation-time * 2) $animation-ease;
  }

  &.exit,
  &.enter,
  &.enter-done {
    display: flex;
  }

  &.enter-active {
    opacity: 1;
  }
}
