@import "../../variables.scss";

.ps5-modal-wrapper {
  position: relative;
  max-width: 80wv;
  max-height: 100%;
  overflow-y: auto;
  background: linear-gradient(45deg, #2d2e33, #191a22);
  border-radius: 5px;
  box-shadow: 5px 0 15px black;

  h2 {
    font-weight: 100;
    margin-top: 0;
  }

  .ps5-btn-group {
    margin-bottom: 1.5rem;

    img {
      width: 25px;
    }      

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .ps5-modal-dialog {
    width: 70vw;
    max-width: 400px;
    padding: 1rem;
    margin-top: 1rem;
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (min-width: 576px) {
      padding: $margin-x;
    }

    .user-active {
      position: relative;
      &::before {
        content: "";
        position: absolute;      
        right: 2px;
        bottom: 10px;
        width: 15px;
        height: 15px;
        background-color: rgb(44, 255, 97);
        border-radius: 50%;
      }
    }

    img.avatar {
      width: 5rem;
      border-radius: 50%;
    }

    .avatar-plus {
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        margin-bottom: 1.2rem;
      }
    }

    .trophies-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      text-align: center;
      & p {
        margin: 0 0 1.5rem 0;
      }
    }

    .edit-logout {
      display: flex;
      align-items: center;
      justify-content: center;  
      text-align: center; 
      & svg {
        width: 30px;
        height: 30px;
        &:hover {
          cursor: pointer;
        }
      }
      & .edit {
        margin-right: 1rem;        
      }
    }

    .line {
      width: 100%;
      height: 1px;
      background: rgba(255, 255, 255, 0.3);
      margin-bottom: 1rem;
    }

  }
  .ps5-modal-image,
  .ps5-modal-btn {
    position: absolute;
    z-index: 1;
    right: 1rem;
    top: 1rem;
  }
}