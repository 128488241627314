@import "../variables.scss";

.ps5-bg {
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: scale(1.05);

  .ps5-bg-item {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    transition: opacity $animation-time $animation-ease,
      transform $animation-time $animation-ease;
    opacity: 0;
    transform: translateX(-5%);

    &.active {
      opacity: 1;
      transform: translateX(0%);

      ~ .ps5-bg-item {
        transform: translateX(5%);
      }
    }
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.8) 35%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.8) 80%
    );
  }
}
