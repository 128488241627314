.ps5-preview {
  margin-top: 5rem;

  table {
    width: 100%;
  }

  @media (min-width: 576px) {
    flex-wrap: nowrap;
  }

  .ps5-disk {
    margin-top: 1rem;

    @media (min-width: 576px) {
      margin: 0;
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 300;
  }

  p {
    width: 30%;
     @media (max-width: 1200px) {
      width: 100%;
    }
  }
} 
