@import "../../variables.scss";

.ps5-disk {
  position: relative;
  display: block;
  width: 100%;
  max-width: 300px;
  background-size: cover;
  background-position: center;

  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 100%;
    background: linear-gradient(to bottom, transparent, rgba(black, 1));
    background-position: 50% 101%;
    background-repeat: no-repeat;
    background-size: 100% 0%;
    transition: background-size $animation-time $animation-ease; 
  }

  div {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 1rem;

    > * {
      margin: 0;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 200;
    }

    img {
      display: block;
      margin-block-start: 0.2em;
      margin-inline-start: -5px;
    }

    p {
      font-weight: 400;
      font-size: 1rem;
    }
  }
}
