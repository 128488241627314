@import "../../variables.scss";

.ps5-top {
  font-size: 1.5rem;
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  gap: 1rem;

  @media (min-width: 768px) {
    margin-left: 2rem;
    margin-right: 2rem
  }

  ul {
    gap: 1rem;

    @media (min-width: 768px) {
      gap: 2rem;
    }

    @media (min-width: 992px) {
      gap: $margin-x;
    }

    #user-active::before {
        content: "";
        position: absolute;      
        right: 3px;
        bottom: 6px;
        width: 10px;
        height: 10px;
        background-color: rgb(44, 255, 97);
        border-radius: 50%;
    }

    img {
      width: 32px;
      border-radius: 50%;
    }

    i {
      font-size: 2rem;
    }
  }
}

.ps5-top-btn {
  display: block;
  padding: 5px;
  min-width: 62px;
  text-align: center;
  opacity: 0.5;
  transform: opacity $animation-time $animation-ease;

  &.active {
    opacity: 1;
    font-weight: 400;
  }
}
