table {
  width: 100%;
}

table thead {
  height: 50px;
  vertical-align: baseline;
}

table thead td:last-child {
  padding-right: 20px;
  @media (max-width: 500px) {
    padding-right: 10px;
    min-width: 105px;
    max-width: 105px;
  }
}

table thead td:not(:first-child)  {
  text-align: end;
  width: 150px;
}

.tbl-content {
  width: 100%;
  height: calc(100vh - 29em);
  overflow-x: auto;

  @media (max-width: 500px) {
    height: calc(100vh - 26em);
  }
}

table tbody tr td:last-child {
  width: 120px;
  padding-right: 20px;
  @media (max-width: 500px) {
    max-width: 105px;
    min-width: 105px;
    padding-right: 10px;
  }
}

table tbody tr td:not(:first-child) {
  text-align: end;
  width: 150px;
}

table img {
  width: 100px;
  padding-right: 5px;
  @media (max-width: 500px) {
    width: 80px;
  }
}

.sort {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

::-webkit-scrollbar {
  width: 1px;
  background-color: rgb(52, 52, 52);
} 
::-webkit-scrollbar-thumb {
  width: 1px;
  background-color: rgb(130, 130, 130);
}