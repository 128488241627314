@import "../variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  font-size: 1.2rem;
  color: white;
  background-color: black;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

button,
a {
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  @extend .ps5-shine;

  &:hover,
  &:focus {
    outline: none;
    color: inherit;
  }
}

p {
  margin-bottom: 1.5rem;  
}

select {
  background: transparent;
  color: white;
  border: none;
  border-bottom: 1px solid rgba(92, 92, 92, 0.463);
  margin-left: 7px;
  & option {
    background: #23385485;
    color: white;
    border: none;
  }
}

img.plus {
  width: 28px;
  margin-left: 10px;
}

img.trophy { 
  width: 50px;
  opacity: 0.4;  
  padding: 8px;
}

img.trophy.won {
  opacity: 1;
}

#load-witcher {
  display: none;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 2px solid rgb(122, 122, 122);
  border-radius: 50%;
  border-top-color: rgb(245, 245, 245);
  animation: spinner 500ms linear infinite;
}

.loading-center {
  margin: 0 auto;
}

.ps5-shine {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: -4px;
    top: -4px;
    right: -4px;
    bottom: -4px;
    border: 2px solid white;
    border-radius: 4px;
    transition: $animation-time $animation-ease;
    opacity: 0;
    transform: scale(0.95);
    background-image: linear-gradient(
      -45deg,
      transparent 30%,
      rgba(white, 0.4),
      transparent 70%
    );
    background-size: 600%;
    animation-duration: 7s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  &.focus,
  &:focus,
  &:hover {
    &:after {
      opacity: 1;
      transform: scale(1);
      animation-name: animation-shine;

      @keyframes animation-shine {
        0% {
          background-position: 100%;
        }
        30% {
          background-position: 0%;
        }
        100% {
          background-position: 0%;
        }
      }
    }
  }
}

.alert {
  border: 1px;
  margin-bottom: 15px;
  margin-left: 0;
  pointer-events: none;
  &.alert-danger:after{
    border-color: rgba(187, 1, 1, 0.94);
  }
  &.alert-success:after{
    border-color: rgba(1, 187, 16, 0.94);
  }
}

.ps5-btn-group {
  display: flex;
  align-items: center;
  gap: 0.7rem;
} 

.ps5-btn {
  border: none; 
  border-radius: 30px;
  width: 200px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: color $animation-time $animation-ease,
  background-color $animation-time $animation-ease;
  will-change: color, background-color;

  &.ps5-btn-mono {
    width: 40px;
    border-radius: 50%;
  }

  &.ps5-btn-lg {
    height: 50px;
    font-weight: 400;

    &.ps5-btn-mono {
      width: 50px;
    }
  }

  &.ps5-btn-primary {
    background-color: rgba(rgb(55, 55, 55), 0.6);
    margin-top: 2rem;
    font-size: large;
  }

  &.ps5-btn-dark {
    background-color: rgba(rgba(19, 13, 13, 0.577), 0.6);
    margin-top: 2rem;
    font-size: large;
  }

  &:after {
    border-radius: inherit;
  }

  &.focus,
  &:focus,
  &:hover {
    color: lighten(black, 30);
    background-color: white;
  }
}

.ps5-link {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    border-bottom: 1px solid;
  }

  &:after {
    content: none;
  }
}

.ps5-animate-from-bottom {
  animation-name: animation-from-bottom;
  animation-duration: $animation-time;
  animation-timing-function: $animation-ease;
  animation-fill-mode: forwards;
  animation-delay: ($animation-time);
  transform: translateY(5%);
  opacity: 0;

  @keyframes animation-from-bottom {
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
}

.ps5-container {
  padding-left: 2rem;
  padding-right: 2rem;

  @media (min-width: 768px) {
    padding-left: $margin-x;
    padding-right: $margin-x;
  }

  @media (min-width: 992px) {
    padding-left: (2 * $margin-x);
    padding-right: (2 * $margin-x);
  }

  @media (min-width: 992px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1500px;
  }
}

.ps5-page {
  overflow: hidden;

  &.enter {
    .ps5-bg:after {
      opacity: 0;
    }

    .ps5-bg {
      transform: scale(1);
    }

    .ps5-sheet {
      opacity: 0;
    }

    .ps5-content {
      opacity: 0;
    }

    .ps5-menu li {
      transform: translateX(500px) scale(0);
    }
  }

  &.enter-active {
    .ps5-bg:after {
      opacity: 1;
      transition: ($animation-time * 2) ($animation-time * 5) $animation-ease;
    }

    .ps5-bg {
      transform: scale(1.05);
      transition: $animation-time ($animation-time * 4) $animation-ease;
    }

    .ps5-sheet {
      opacity: 1;
      transition: ($animation-time * 2) ($animation-time * 7) $animation-ease;
    }

    .ps5-content {
      opacity: 1;
      transition: ($animation-time * 2) ($animation-time * 7) $animation-ease;
    }

    .ps5-menu li {
      transform-origin: center; 
      transform: translateX(0) scale(1);
      transition: ($animation-time * 2) $animation-ease;

      @for $i from 1 through 8 {
        &:nth-child(#{$i}) {
          transition-delay: $animation-time * calc($i/5 + 6);
        }
      }
    }
  }
}

.ps5-sheet {
  position: relative;
}

.ps5-hero {
  display: flex;
  flex-direction: column;

  &.ps5-hero-fullscreen {
    min-height: 100vh;
  }
}

// Error page

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(../assets/ps-home.jpg) ;
  height: 100vh
}