.trophy-earned {
  position: absolute;
  width: 400px;
  top: -13rem;
  right: 0;
  margin-right: 2rem;
  border-radius: 12px;
  background: linear-gradient(to right, #2a2a2aca, #000000cf);
  padding: 5px 25px 5px 25px;
  overflow: hidden;
  opacity: 0;
  transform: translateX(2%);
  transition: all 0.1s linear;

  &.active{
    transform: translateX(0%);
    opacity: 1;
  }  

  & .trophy-earned-content{
    display: flex;
    flex-direction: row;
    align-items: center;

    & img {
      width: 46px;
    }

    & .message{ 
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 20px;


      & .trophy-earned-text {
        font-size: 1.4rem;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.989);
      }

      & p {
        width: 100%;
        font-size: 1.1rem;
        margin: 5px 0;

      }
    }
  }

  @media (max-width: 992px) {
    width: 265px;
    top: -2rem;
    left: 0;
    padding: 3px 25px 3px 15px;

    & .trophy-earned-content{
      & img {
        width: 40px;
      }
      & .message{
        & .trophy-earned-text {
          font-size: 1.1rem;
        }
        & p {
          font-size: 1rem;
        }
      }

  }}
}